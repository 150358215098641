.no-style-link{
 text-decoration: none;   
}

.top-nav-bar{
    background-color: #3368C6;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.top-nav-bar-left-side{
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center
}

.top-nav-bar-right-side{
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.top-nav-link{
    color: white;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    margin-right: 20px;
}

.top-nav-link-icon{
    font-size: 18px!important;
    margin-right: 5px;
    opacity: 0.7;
}

.top-nav-link:hover .top-nav-link-icon{
    opacity: 1;
}

.login-button-span{
  color: white;
  opacity: 0.7;
  margin-right: 10px;
}

.login-button-span:hover{
  opacity: 1;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .top-nav-bar-left-side{
    display: none;
  }

  .top-nav-bar-right-side{
    display: none;
  }

  
  .top-nav-bar{
    justify-content: flex-end;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  .top-nav-bar-left-side{
    display: none;
  }

  .top-nav-bar-right-side{
    display: none;
  }

  
  .top-nav-bar{
    justify-content: flex-end;
  }
  
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .top-nav-bar-left-side{
    display: none;
  }

  .top-nav-bar-right-side{
    display: none;
  }

  .top-nav-bar{
    justify-content: flex-end;
  }

  
}   

