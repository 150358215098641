.admin-panel-button{
    color: white;
    background-color: #3368C6;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 20px;
    transition: 0.3s linear;
}

.admin-panel-button:hover{
    background-color: #0026ff;
}