.multi-page-form{
    box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
    border-radius: 10px;
    font-family: sans-serif;
    background-color: white;
    padding: 50px 40px;
}

.multipage-controller-list{
  list-style: none;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid rgb(211, 211, 211);
  padding: 0;
}

.multipage-controller-list li{
    padding: 10px 20px;
    border-bottom: 3px solid transparent;
    transition: 0.4s ease;
    transform: translateY(2px);
}

.multipage-controller-list li:hover{
    border-bottom: 3px solid #13CDD9!important;
    color: #13CDD9;
}

.multipage-controller-list-active{
    border-bottom: 3px solid #13CDD9!important;
    color: #13CDD9;
}

.multi-page-main-form-container{
    position: relative; 
}

.extra-label{
    margin-right: 75px;
    font-size: 16px;
    font-weight: bold;
    margin-top: 15px;
    width: 120px;
}