.progress-outer-bar{
    height: 8px;
    background-color: #E9ECEF;
    border-radius: 20px;
    margin-bottom: 20px;
}

.progress-inner-bar{
   height: 100%;
   border-radius: 20px;
}