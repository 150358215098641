@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.website-footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-image: url("../../Images/footerBackground.jpg");
    position: relative;
    font-family: 'Poppins', sans-serif;
    position: relative;
}

.footer-about-content{
    color: rgb(168, 168, 168);
    font-size: 14px;
    text-align: justify;
}

.footer-content-section{
    width: 25%;
    position: relative;
    z-index: 2;
    margin: 20px;
    margin-bottom: 80px;
}

.footer-content-section h2{
  color: white;
  font-weight: 400;
  margin-bottom: 10px;
}

.footer-content-section h2::before{
    content: "";
    position: absolute;
    top: 60px;
    left: 0;
    width: 50px;
    height: 4px;
    background: #48c7ec;
}

.footer-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(2, 41, 131, 0.95)
}

.footer-contact-element{
    color: rgb(221, 221, 221);
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.footer-icon{
    font-size: 27px!important;
    margin-right: 10px;
    color: white;
}

.footer-links{
    text-decoration: none;
    display: block;
    color: rgb(170, 169, 169);  
    margin-bottom: 15px;  
}

.footer-links::before{
    content:"\276f";
    margin-right: 10px;
}

.footer-links:hover{
    color: #36C7EC;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
    
    .website-footer{
        flex-direction: column;
     }
 
     .footer-content-section{
        
         width: 90%;
         margin: 10px;
 
     }
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
    
}
/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
    .website-footer{
       flex-direction: column;
    }

    .footer-content-section{
       
        width: 90%;
        margin: 10px;

    }
    

}   