@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

body {
  font-family: "Nunito", sans-serif !important;
}

.admin-panel-sidebar {
  background-color: #ffffff;
  width: 16%;
  height: auto;
  min-height: 100% !important;
  transition: 0.2s linear;
  display: flex;
  flex-direction: column;
  transition: 0.4s ease;
}

.admin-panel-sidebar-inner {
  position: fixed;
  width: 16%;
  transition: 0.4s ease;
  overflow-y: scroll;
  margin-top: 80px;
  height: 100%;
  padding-top: 30px;
}

/* width */
.admin-panel-sidebar-inner::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.admin-panel-sidebar-inner::-webkit-scrollbar-track {
  background: #f7f8fb;
}

/* Handle */
.admin-panel-sidebar-inner::-webkit-scrollbar-thumb {
  background: rgba(51, 104, 198,0.2);
}

/* Handle on hover */
.admin-panel-sidebar-inner::-webkit-scrollbar-thumb:hover {
  background: rgb(51, 104, 198);
}

.dropdown-header-link {
  position: relative;
}

.dropdown-header-link::after {
  content: "\276f";
  font-size: 13px;
  position: absolute;
  right: 0;
  margin-top: 0.5px;
}

.link-container .dropdown-header-link::after {
  content: "";
}

.hidden-class {
  display: none !important;
}

.hidden-sidebar {
  width: 0%;
  overflow: hidden;
}

.profile-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sidebar-top-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 16%;
  box-shadow: 0 0 10px 0 rgba(68, 102, 242, 0.05);
  position: fixed;
  top: 0;
  background-color: #ffffff;
  font-size: 25px;
  font-weight: bold;
  color: #3368C6;
}

.side-panel-profile-image {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(68, 102, 242, 0.3);
}

.profile-name {
  color: #3368C6;
  font-weight: bold;
  font-family: sans-serif;
  margin: 0;
  margin: 5px;
  margin-top: 20px;
  font-size: 15px;
}

.profile-designation {
  color: gray;
  margin: 0;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: bold;
}

.sidebar-icons {
  font-size: 18px !important;
}

.sidebar-link {
  padding: 12px 20px;
  transition: 0.5s ease;
  cursor: pointer;
  color: rgb(97, 97, 97) !important;
  position: relative;
}

.sidebar-link:hover {
  color: #3368C6 !important;
  padding-left: 25px;
}

.sidebar-link-header-div {
  display: flex;
  flex-direction: row;
}

.sidebar-link-header-div .sidebar-links-heading {
  margin: 0;
  margin-left: 10px;
  font-weight: 600;
  font-size: 14px;
  font-family: sans-serif;
  letter-spacing: 0.6px;
  margin-top: 2px;
}

.sidebar-links-first-sub-dropdown {
  display: none;
}

.visible-sidebar-link {
  display: block !important;
}

.sidebar-links-first-sub-dropdown li {
  padding: 5px;
  font-size: 14px;
  color: rgb(138, 138, 138);
}

.sidebar-links-first-sub-dropdown li:hover {
  color: #3368C6;
}

.sidebar-links-first-sub-dropdown li:after {
  right: 10px;
}

.hoverable-side-link {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.sub-dropdown-link {
  display: none;
}

.exit-icon{
  transform: rotate(180deg);
}


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  .admin-panel-sidebar-inner{
    width: 50%;
    background-color: white;
    z-index: 1000;
  }



  .sidebar-top-logo{
    width: 50%;
    z-index: 1000;
  }
}   
