.status-cards-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
    width: 100%;
}

.dual-chart-container{
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.chart-container{
  width: 50%;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.table-container{
  width: 50%;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.inner-table{
  box-shadow: none;
  width: 100%;
}

.chart-container h3{
  margin: 0;
  display: block;
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(238, 238, 238);
}

.full-width-chart-container h3{
  margin: 0;
  display: block;
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(238, 238, 238);
}

.table-container h3{
  margin: 0;
  display: block;
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(238, 238, 238); 
}

.dashboard-heading{
  margin-left: 20px;
  margin-top: 0;
}

.link-container{
  text-decoration: none;
}

.small-line-chart-container{
  width: 200px;
  height: 200px;
  background-color: white;
}

.store-status-section{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.store-status-dual-card{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.store-status-card{
  width: 50%;
  background-color: white;
  padding: 20px;
  text-align: left;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
  font-family: sans-serif;
}

.store-status-card-heading{
  font-family: 'nunito';
  margin:0;
  margin: 5px 0;
  font-size: 15px;
  font-weight: bold;
}

.store-status-percent{
  margin: 0;
  font-size: 35px;
  margin: 15px 0;
}

.store-status-big-text{
  font-size: 34px;
  font-weight: bold;
  margin: 0;
}

.store-status-mid-text{
  font-size: 17px;
  margin: 0;
  margin: 15px 0;
}

.store-status-small-text{
  font-size: 13px;
  margin: 0;
  color: rgb(143, 143, 143);
  margin: 15px 0;
}

.store-status-card-mid-div{
  position: relative;
}

.store-status-card-icon-container{
  position: absolute; 
  right: 0;
  top: 0;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-width-chart-container{
  width: 92%;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.small-dual-chat-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.small-chart{
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 40px;
}
/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
  .store-status-section{
    flex-direction: column;
  }

  .store-status-dual-card{
    flex-direction: row;
    width: 100%;
  }

  .store-status-card{
    width: 100%;
  }

  .dual-chart-container{
    flex-direction: column;
  }

  .chart-container{
    width: 80%;
  }

  .table-container{
    width: 80%;
  }

  .admin-panel-status-card{
    width: 50%!important;
  }
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    .status-cards-container{
        flex-direction: column;
    }

    .store-status-section{
      flex-direction: column;
    }

    .store-status-dual-card{
      flex-direction: row;
    }

    .store-status-card{
      width: 100%;
    }
    .dual-chart-container{
      flex-direction: column;
    }

    .chart-container{
      width: 80%;
    }

    .table-container{
      width: 80%;
    }

    .admin-panel-status-card{
      width: 50%!important;
    }
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .status-cards-container{
        flex-direction: column;
        width: 100%;
    }
    .dual-chart-container{
      flex-direction: column;
    }

    .chart-container{
      width: 80%;
    }

    .table-container{
      width: 80%;
    }
    .store-status-section{
      flex-direction: column;
    }

    .store-status-dual-card{
      flex-direction: column;
    }

    .store-status-card{
      width: 130%;
    }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
    .status-cards-container{
        flex-direction: column;
    }

    .dual-chart-container{
      flex-direction: column;
    }

    .chart-container{
      width: 80%;
    }

    .table-container{
      width: 80%;
    }

    .store-status-section{
      flex-direction: column;
    }

    .store-status-dual-card{
      flex-direction: column;
    }

    .store-status-card{
      width: 130%;
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .status-cards-container{
        flex-direction: column;
    }

    .dual-chart-container{
      flex-direction: column;
    }

    .chart-container{
      width: 80%;
    }

    .table-container{
      width: 80%;
    }

    .store-status-section{
      flex-direction: column;
    }

    .store-status-dual-card{
      flex-direction: column;
    }

    .store-status-card{
      width: 130%;
    }
}  