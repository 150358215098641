.auth-pop-up-form{
    position: fixed;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    width: 28%;
    top: 10%;
    left: 34%;
    animation: fadeInDown 0.7s ease;
}


.auth-pop-up-form-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.auth-pop-input{
    margin: 10px 0;
    font-size: 17px;
    padding: 10px;
    outline: none;
    border: none;
    border-bottom: 3px solid rgb(202, 202, 202);
    letter-spacing: 1px;
    width: 80%;
    transition: 0.4s ease;
}

.auth-pop-up-heading{
    font-size: 30px;
    font-weight: bold;
    margin: 10px 0 30px 0;
    color: #3368C6;
}

.auth-pop-input:focus{
    border-color: gray;
}

.auth-pop-button{
    width: 82%;
    padding: 10px;
    font-size: 20px;
    background-color: #3368C6;
    color: white;
    border: none;
    outline: none;
    border-radius: 3px;
    margin: 30px 10px;
    transition: 0.4s ease;
}

.auth-pop-button:hover{
    background-color: #0139b3;
}

.mode-change-link{
    margin: 0;
}

.mode-change-link:hover{
    color: blue;
} 

.invalid-cred-text{
    color: red;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.loading-spinner{
  padding: 10px;
  display: inline-block;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.822);
  border-bottom-color: transparent;
  border-left-color: transparent;
  animation: loading 2s infinite ease-in-out;
}

@keyframes fadeInDown {
    0% {
       opacity: 0;
       transform: translateY(-100px);
    }

    
    100% {
       opacity: 1;
       transform: translateY(0)
    }
 } 

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(1440deg);
  }

} 

 /* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
   
    .auth-pop-up-form{
        width: 70%;
        left: 11%;   
    }
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
   
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
   
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .auth-pop-up-form{
        width: 70%;
        left: 5%;    
    }

}   