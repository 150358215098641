.profiles-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.basic-profile-container{
   width: 32%;
   background-color: white;
   margin: 15px;
   box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
   border-radius: 10px;
   font-family: sans-serif;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 20px;
   transition: 0.4s ease;
}
.basic-details{
    text-align: center;
}

.basic-details img{
    width:90px;
    height: 90px;
    border-radius: 50%;
}

.detailed-profile-container{
   width: 64%;
   background-color: white;
   margin: 15px;
   box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
   border-radius: 10px;
   font-family: sans-serif;
}

.employee-status-profile{
    width: 90%;
}

.horizontal-rule{
    border-color: rgb(255, 255, 255);
    background-color: rgb(255, 255, 255);
    width: 100%;
    border-bottom: none;
    margin-top: 60px;
}

.progress-bar-heading{
    color: gray;
    margin: 10px 0;
    font-size: 15px;
    letter-spacing: 1px;
    font-weight: normal;
}


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .profiles-container{
        flex-direction: column;
    }

    .basic-profile-container{
        width: 90%;
    }

    .detailed-profile-container{
        width: 100%;
    }
}   
