.product-list-display{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 30px 0;
}

.add-btn{
    margin: 10px 30px;
}

.product-list-display span{
    background-color: lightgray;
    border-radius: 5px;
    margin: 2px 10px;
    padding: 10px;
}