.main-navigation-bar{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0px 13px 25px -12px rgba(0,0,0,0.2);
    position: sticky;
    top: 0;
    z-index: 10;
}

.navigation-links-container{
    display: flex;
    flex-direction: row;
    width: 60%;
    justify-content: flex-end;
}

.nav-logo-container{
    width: 20%;
    font-size: 24px;
    font-weight: bold;
    color: rgb(0, 110, 255);
    display: flex;
    flex-direction: row;
}

.logo-image{
    width: 54px;
    height: 40px;
}

.logo-content{
    width: 160px;
    height: 40px;
}

.mobile-menu-icon{
    display: none!important;
}

.mobile-menu{
    display: none;
}

.mobile-navigation-links-container{
    background-color: white!important;
    margin: 0;
    padding: 0;
}

.mobile-menu-link{
    display: block;
    text-decoration: none;
    color: #3368C6;
    padding: 20px;
    border-top: 1px solid #3368C6;
    font-weight: bold;
}

.mobile-menu-link:hover{
    color: blue;
}

@keyframes fadeDown{
    0%{
      transform: translateY(-100px);
    }

    100%{
       transform: translateY(0);
    }
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
    .navigation-links-container{
        display: none;
    }
 
    .nav-logo-container{
       margin: 20px;
       margin-left: 5px;
       float: left;    
    }
 
    .main-navigation-bar{
        justify-content: flex-start;
        box-shadow: none;
    }

    .mobile-menu{
        display: block;
    }
 
    .mobile-menu-icon{
        display: block!important;
        position: absolute;
        right: 20px;
        font-size: 34px!important;
        color: black!important;
    }
 
    .mobile-menu-icon:hover{
        color: rgb(0, 132, 255)!important;
    }
 
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .navigation-links-container{
        display: none;
    }
 
    .nav-logo-container{
       margin: 20px;
       margin-left: 5px;
       float: left;    
    }
 
    .main-navigation-bar{
        justify-content: flex-start;
        box-shadow: none;
    }

    .mobile-menu{
        display: block;
    }
 
    .mobile-menu-icon{
        display: block!important;
        position: absolute;
        right: 20px;
        font-size: 34px!important;
        color: black!important;
    }
 
    .mobile-menu-icon:hover{
        color: rgb(0, 132, 255)!important;
    }
 
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
    .navigation-links-container{
        display: none;
    }
 
    .nav-logo-container{
       margin: 20px;
       margin-left: 5px;
       float: left;    
    }
 
    .main-navigation-bar{
        justify-content: flex-start;
        box-shadow: none;
    }

    .mobile-menu{
        display: block;
    }
 
    .mobile-menu-icon{
        display: block!important;
        position: absolute;
        right: 20px;
        font-size: 34px!important;
        color: black!important;
    }
 
    .mobile-menu-icon:hover{
        color: rgb(0, 132, 255)!important;
    }
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
   
   .navigation-links-container{
       display: none;
   }

   .nav-logo-container{
      margin: 20px;
      margin-left: 5px;
      float: left;    
   }

   .main-navigation-bar{
       justify-content: flex-start;
       box-shadow: none;
   }

   .mobile-menu{
       display: block;
   }

   .mobile-menu-icon{
       display: block!important;
       position: absolute;
       right: 20px;
       font-size: 34px!important;
       color: black!important;
   }

   .mobile-menu-icon:hover{
       color: rgb(0, 132, 255)!important;
   }

}   