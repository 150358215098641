.testimonial-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    margin: 30px;
}

.testimonial-card{ 
   background-color: white;
   box-shadow: 0 0 10px 0 rgba(43,52,59,.1);
   color: #788aaf;
   text-align: justify;
   font-style: normal;
   font-weight: 400;
   padding: 20px;
   padding-top: 40px;
   margin: 0;
   font-size: 16px;
   line-height: 26px;
   font-family: "Roboto";
   min-height: 160px;
   max-height: 160px;
   position: relative;
}

.inverted-commas-image{
    position: absolute;
    width: 120px;
    height: 100px;
    top: -50px;
    right: -20px;
}

.testimonial-person{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.person-image{
    width: 82px;
    height: 82px;
    border-radius: 50%;
}

.person-name{
    font-size: 17px;
    font-family: "Poppins",Arial,Helvetica,sans-serif;
    font-weight: 600;
    color: #18336c;
    margin-left: 10px;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
    .testimonial-container{
        width: 100%;
     }
 
     .testimonial-card{
         min-height: 200px;
         max-height: 200px;
     }  
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .testimonial-container{
        width: 100%;
     }
 
     .testimonial-card{
         min-height: 200px;
         max-height: 200px;
     }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
    .testimonial-container{
        width: 100%;
     }
 
     .testimonial-card{
         min-height: 200px;
         max-height: 200px;
     }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .testimonial-container{
       width: 100%;
    }

    .testimonial-card{
        min-height: 200px;
        max-height: 200px;
    }
}   