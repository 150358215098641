.sub-heading{
    font-family: "Poppins";
    margin: 0;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 26px;
    line-height: 35px;
    color: #18336c;
}

.sub-points{
    text-align: justify;
    color: #8A9ABA;
}

.sub-small-heading{
    font-family: "Poppins";
    margin: 0;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 18px;
    line-height: 28px;
    color: #18336c;
}