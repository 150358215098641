.add-product-form{
    box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
    border-radius: 10px;
    font-family: sans-serif;  
    background-color: white;
    display: flex;
    justify-content:center;
    align-items: center;
    padding: 60px 10px;
}

.add-product-image-form{
   width: 40%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.add-product-details-form{
   width: 60%;
}

.add-product-image-input{
    height: 400px;
}

.add-product-btn{
    float: right;
}

.discard-product-btn{
    float: right;
    background-color: rgb(179, 179, 179);
}

.discard-product-btn:hover{
    background-color: gray;
}


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .add-product-form{
        flex-direction: column;
    }

    .add-product-details-form{
        width: 100%;
    }
}   
