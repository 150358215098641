.contact-us-cards-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
}

.contact-us-card{
    background-color: white;
    border-radius: 10px;
    margin: 20px;
    padding: 15px 40px;
    box-shadow: 0 0 10px 0 rgba(43,52,59,.1);
    width: 25%;
}

.contact-us-card-heading{
    font-family: "Poppins";
    font-weight: 700;
    text-transform: capitalize;
    font-size: 20px;
    margin: 10px 0;
    line-height: 48px;
    color: #18336c;
}

.contact-us-label{
    color: rgb(0, 70, 161);
}

.contact-us-value{
    color: #8E9CBA;
}

.contact-us-form-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 150px;
    height: 500px;
}

.contact-us-image-container{
    width: 25%;
    height: 100%;
}

.contact-us-image-container img{
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 100px;
}

.dual-input-container-contact-form{
    display: flex;
    flex-direction: row;
}

.contact-us-form-inner-container{
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(43,52,59,.1);
    padding: 30px;
    transform: translateX(-30px);
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 100px;
}

.contact-form-input{
    background-color: #F5F9FD;
    border: none;
    margin: 20px;
    color: #8093a8;
    padding: 13px 15px;
    font-weight: 400;
    text-transform: inherit;
    border: 1px solid #F5F9FD;
    font-size: 15px;
    outline: none;
    line-height: inherit;
    width: 50%;
    transition: 0.4s ease;
    border-radius: 2px;
    font-size: 15px;
}

.contact-form-input:focus{
    border-color: #3368C6;
}

.contact-form-input::placeholder{
    color: #8E9CBA;
}

.contact-us-form{
    width: 100%;
    text-align: center;
}

.contact-form-heading{
    font-family: "Poppins";
    margin: 25px;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 34px;
    line-height: 48px;
    color: #18336c;
    text-align: left;
}

.contact-form-textarea{
    background-color: #F5F9FD;
    border: none;
    margin: 20px;
    color: #8093a8;
    padding: 13px 15px;
    font-weight: 400;
    text-transform: inherit;
    border: 1px solid #F5F9FD;
    font-size: 15px;
    outline: none;
    line-height: inherit;
    width: 100%;
    transition: 0.4s ease;
    border-radius: 2px;
    font-size: 15px;
}

.contact-form-textarea:focus{
    border-color: #3368C6;
}

.contact-form-textarea::placeholder{
    color: #8E9CBA;
}

.contact-form-button{
  background-color: #3368c6;
  color: #fff;
  padding: 11px 28px;
  border-radius: 3px;
  transition: all .2s ease-in-out;
  line-height: normal;
  font-size: 14px;
  border: none;
  outline: none;
  margin-top: 10px;
}

.contact-form-button:hover{
    background-color: darkblue;
}

.map-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
}

.map{
    width: 100%;
    height: 100%;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    .contact-us-cards-container{
        flex-direction: column;
    }

    .contact-us-card{
        width: 50%;
    }
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .contact-us-cards-container{
        flex-direction: column;
    }

    .contact-us-card{
        width: 50%;
    }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
    .contact-us-cards-container{
        flex-direction: column;
    }

    .contact-us-card{
        width: 50%;
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .contact-us-cards-container{
        flex-direction: column;
    }

    .contact-us-card{
        width: 50%;
    }

    .contact-us-image-container{
        display: none;
    }

    .contact-us-form-container{
        width: 100%;
        display: block;

    }

    .contact-us-form-inner-container{
        width: 100%;
        border-radius: 0;
    }

    .contact-us-form{
        width: 100%;
    }

}   