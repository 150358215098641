.admin-panel-profile-image{
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.nav-bar-admin{
    background-color: #FFFFFF;
    height: 80px;
    width: 84%;
    position: fixed;
    top: 0;
    box-shadow: 0 0 10px 0 rgba(68,102,242,.05);
    z-index: 1000;
}

.nav-bar-admin-maxify{
    width: 100%;
}
.nav-bar-admin-icon{
    font-size: 30px!important;
    color: #3368C6;
    margin-left: 10px;
    margin-right: 10px;
}

.nav-bar-admin-icon:hover{
    color: #2d9df8;
}

.nav-bar-admin-logo{
    font-size: 28px;
    font-weight: Bold;
    color: gray;
    margin-left: 30px;
}

.nav-bar-admin-left{
    float: left;
    height: 100%;
    display: flex;
    align-items: center;
}

.nav-bar-admin-right{
    float: right;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 30px;
}

.nav-bar-admin-search-bar-div{
    display: flex;
    flex-direction: row;
    overflow: hidden;
    border-radius: 30px;
    background-color: #F8F8F9;
    width: 300px;
    border: 1px solid rgb(238, 238, 238);
    height: 50%;
}

.search-icon-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    padding-left: 20px;
}

.search-icon{
    color: #8E8E8E;
}

.navbar-search-input{
    border: none;
    outline: none;
    background-color: #F8F8F9;
    padding: 12px;
    padding-left: 15px;
    font-size: 16px;
    font-family: sans-serif;
}



/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .nav-bar-admin-right{
        display: none;
    }

    .nav-bar-admin{
        width: 50%;
        right: 0;
    }

    .nav-bar-admin-maxify{
       width: 100%!important;   
    }

    
}   
