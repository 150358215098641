.product-card{
  width: 20%;
  background-color: white;
  padding: 30px 15px;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 10px;
  font-family: sans-serif;
  margin: 15px;
  text-align: left;
  box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
  min-height: 400px;
  max-height: 400px;
  position: relative;
}

.product-card-image{
  width: 80%;
  height: 65%;
}

.product-card-image-container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    max-height: 300px;
}

.product-card-details-container{
    width: 90%;
}

.star-icons-container{
  margin: 10px 0 4px 0;
}

.product-card-star-icon-golden{
  height: 18px!important;
  width: 18px!important;
  color:#FFA200;
}

.product-card-star-icon{
  height: 17px!important;
  width: 17px!important;
  color: #DDDDDD;
}

.product-card-description{
   margin: 0;
   font-size: 17px;
   color: #8b8b8b;
   margin-bottom: 3px;
}

.sale-price-product-card{
    font-weight: bold;
    font-size: 20px;
    margin-right: 6px;
}

.cost-price-product-card{
   color: rgb(179, 179, 179);
   text-decoration: line-through;
}

.delete-icon-product-card{
   position: absolute;
   right: 10px;
   top: 10px;
   color: #3368C6;
}

.delete-icon-product-card:hover{
  color: red;
}

.delete-prompt-container{
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-prompt{
  background-color: rgb(78, 78, 78);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  animation: 0.8s popup ease;
  overflow: hidden;
}

.delete-prompt-text{
  font-size: 20px;
  color: white;
}

.delete-prompt-delete-button{
    border: none;
    outline: none;
    padding: 10px;
    font-size: 17px;
    border-radius: 4px;
    color: white;
    background-color: rgb(221, 26, 0);
    margin: 10px;
    transition: 0.4s ease;
}

.delete-prompt-delete-button:hover{
  transform: scale(1.2);
}

.delete-prompt-cancel-button{
  border: none;
  outline: none;
  padding: 10px;
  font-size: 17px;
  border-radius: 4px;
  color: white;
  background-color: #8b8b8b;
  margin: 10px;
  transition: 0.4s ease;
}

.delete-prompt-cancel-button:hover{
  transform: scale(1.2);
}

@keyframes popup {
  0%{
    transform: scale(0.1);
  }
  50%{
    transform: scale(1.2);
  }
  100%{
    transform: scale(1);
  }
}


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .product-card{
    width: 30%;
  }

  .delete-prompt-container{
    width: 90%;
    height: 90%;
  }
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .product-card{
    width: 40%;
  }

  .delete-prompt-container{
    width: 90%;
    height: 90%;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  .product-card{
    width: 40%;
  }

  .delete-prompt-container{
    width: 90%;
    height: 90%;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .product-card{
      width: 80%;
    }

    .delete-prompt-container{
      width: 90%;
      height: 90%;
    }
}   
