.image-file-input{
    display: none;
}

.image-input{
    width:300px;
    height: 300px;
    position: relative;
    border: 1px solid rgb(209, 208, 208);
}

.image-input img{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
}

.image-input-overlay{
    background-color: rgba(0, 0, 0, 0.452);
    color: white;
    font-size: 14px;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: none;
    text-align: center;
    animation: fadeIn ease 0.5s;
}

.image-input:hover .image-input-overlay{
    display: flex
}


@keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  