.admin-panel-form-select{
    width: 72%;
    padding: 10px;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 4px;
    height: 50px;
    transition: 0.4s ease;
    outline: none;
    font-size: 16px;
}

.admin-panel-form-select:focus{
    border-color: rgb(0, 217, 255);  
}