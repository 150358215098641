.admin-panel-form-input{
    width: 70%;
    padding: 10px;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 4px;
    height: 25px;
    transition: 0.4s ease;
    outline: none;
    font-size: 16px;
}

.admin-panel-form-input:focus{
    border-color: rgb(0, 217, 255);  
}

.form-input-container{
    display: flex;
    flex-direction: row;
    margin: 20px 0;
}

.form-input-label{
    margin-right: 20px;
    font-size: 16px;
    font-weight: bold;
    margin-top: 15px;
    width: 120px;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
