.website-product-card{
    box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
    background-color: #031B31;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 400px;
    padding: 20px;
    border-radius: 10px;
    margin: 0 30px 30px 30px;
}

.website-product-image-container{
   display: flex;
   justify-content: center;
   align-items:center;
   height: 70%;
   width: 100%;
}

.website-product-image-container img{
    width: 90%;
    height: 90%;
}

.website-product-details-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
}

.website-product-details-container p{
    margin: 0;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    font-size: 25px;
    letter-spacing: 1px;
}

.website-product-details-container p:nth-child(2){
    color:#FFA500;
    font-size: 14px;
}


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    .website-product-card{
        width: 60%;
    }
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .website-product-card{
        width: 40%;
    }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
    .website-product-card{
        width: 40%;
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .website-product-card{
        width: 80%;
    }
}  