.website-stats-section{
    background-color: #3368C6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
}

.stats-main-heading{
    color: white;
    margin: 0;
    margin: 10px 0;
    font-family: "Poppins",Arial,Helvetica,sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 40px;
    line-height: 48px;
}

.stats-sub-heading{
    color: rgb(240, 238, 238);
    margin: 0;
    margin: 10px 0;
    font-size: 18px;
}

.website-stats-section-cards-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.website-stats-section-cards{
    border: 2px double white;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 15%;
    margin: 30px;
    border-radius: 5px;
    display: inline-flex;
    padding: 20px;
}

.website-stats-content-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
}

.website-stats-number{
   font-size: 45px;
   margin: 10px 0;
   transform: translateX(7px);
} 

.website-stats-title{
   margin: 10px 0;
}

.website-stats-icon-container{
    display: flex;
    width: 30%;
}

.website-stats-icons{
    font-size: 100px!important;
}


.products-slider-list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}

.home-page-products-images-container{
    background-color: #ffffffea;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.home-page-products-images-container h1{
    font-family: "Poppins";
    margin: 30px;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 40px;
    line-height: 48px;
    color: #18336c;
}

.home-page-products-images-container-inner::-webkit-scrollbar{
    display: none;
}

.home-page-products-images-container-inner{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
}


.home-page-product-image-container{
    min-height: 400px;
    max-height: 400px;
    max-width: 250px;
    min-width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    animation: scrollanimation 40s linear infinite;
}

.home-page-product-image{
   width: 100%;
}

.product-list-background-image{
    position: absolute;
    background-image: url("../Images/product-list-home.jpg");
    width: 100%;
    height: 100%;
    z-index: -1;
}

@keyframes scrollanimation {

    100%{
        transform: translateX(-600%);
    }
}
/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    .stats-main-heading{
        text-align: center;
        padding: 5px;
    }
 
    .stats-sub-heading{
        text-align: center;
        padding: 2px;
    }
 
    .website-stats-section-cards-container{
        flex-direction: column;
    }
 
    .website-stats-section-cards{
        width: 60%;
    }
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .stats-main-heading{
        text-align: center;
        padding: 5px;
    }
 
    .stats-sub-heading{
        text-align: center;
        padding: 2px;
    }
 
    .website-stats-section-cards-container{
        flex-direction: column;
    }
 
    .website-stats-section-cards{
        width: 60%;
    }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
    .stats-main-heading{
        text-align: center;
        padding: 5px;
    }
 
    .stats-sub-heading{
        text-align: center;
        padding: 2px;
    }
 
    .website-stats-section-cards-container{
        flex-direction: column;
    }
 
    .website-stats-section-cards{
        width: 60%;
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
   .stats-main-heading{
       text-align: center;
       padding: 5px;
   }

   .stats-sub-heading{
       text-align: center;
       padding: 2px;
   }

   .website-stats-section-cards-container{
       flex-direction: column;
   }

   .website-stats-section-cards{
       width: 60%;
   }
}   