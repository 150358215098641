.testimonial{
    background-color: white;
    text-align: center;
    position: relative;
    z-index: 0;
    height: 100%;
    background-image: url("../../Images/testimonialbackground.png");
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.testimonial-top-heading{
  color: #3368c6;
  font-weight: bold;
  letter-spacing: 1.3px;
  font-size: 14px;
  margin: 0;
  font-family: "Roboto",Arial,Helvetica,sans-serif;
  padding: 10px;
}

.testimonial-main-heading{
  font-family: "Poppins",Arial,Helvetica,sans-serif;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 36px;
  line-height: 48px;
  color: #18336c;
  margin: 0;
  padding: 10px;
}

.testimonial-heading-text{
  font-family: "Roboto",Arial,Helvetica,sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #788aaf;
  width: 40%;
}

.testimonial-cards-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .testimonial-cards-container{
    flex-direction: column;
  }

  .testimonial-heading-text{
    width: 90%;
  }
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .testimonial-cards-container{
    flex-direction: column;
  }

  .testimonial-heading-text{
    width: 90%;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  .testimonial-cards-container{
    flex-direction: column;
  }

  .testimonial-heading-text{
    width: 90%;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .testimonial-cards-container{
    flex-direction: column;
  }

  .testimonial-heading-text{
    width: 90%;
  }
}   