@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

#root{
    background-color: #F6F6F9;
}


.admin-panel-content-container{
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 84%;
    justify-content: center;
    align-items: center;
    transition: 0.4s ease;
  }
  
  .admin-panel-content-container-maxify{
    width: 100%;
  }
  
  .admin-panel-page{
    background-color: #F6F6F9;
    display: flex;
    flex-direction: row;
  }
  
  
  /* 
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
  */
  
  @media (min-width: 1281px) {
    
        
      
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
      
    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
      
  }   
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) { 
    
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    
  }   
  