.main-nav-link-container{
    text-decoration: none;
    transition: 0.4s ease;
    list-style-type: none;
    padding: 12px;
    color: #3A5181;
    font-weight: 600;
    border-bottom: 2px solid transparent;
}

.main-nav-link-container:hover{
    color: #396CC7;
    border-bottom: 2px solid #396CC7;
}

.main-nav-link{
    font-size: 17px;
}