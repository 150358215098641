@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  transition: 0.5s ease;
  width: 100%;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  #root{
    width: 100%;
    overflow-x: hidden;
  }
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  #root{
    width: 100%;
    overflow-x: hidden;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  #root{
    width: 100%;
    overflow-x: hidden;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    #root{
      width: 100%;
      overflow-x: hidden;
    }
}   
#root{
    background-color: #F6F6F9;
}


.admin-panel-content-container{
    display: flex;
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 84%;
    justify-content: center;
    align-items: center;
    transition: 0.4s ease;
  }
  
  .admin-panel-content-container-maxify{
    width: 100%;
  }
  
  .admin-panel-page{
    background-color: #F6F6F9;
    display: flex;
    flex-direction: row;
  }
  
  
  /* 
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
  */
  
  @media (min-width: 1281px) {
    
        
      
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
      
    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
      
  }   
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) { 
    
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    
  }   
  
.admin-panel-status-card{
    display: flex;
    flex-direction: row;
    box-shadow: 0 0 5px 2px lightgray;
    background-color: white;
    border-radius: 25px;
    margin: 15px;
    width: 25%;
    height: 130px;
}

.status-card-icon-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.status-card-icon{
    color: white;
    font-size: 34px!important;
}

.status-card-text-container{
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 0;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.status-card-main-content{
    margin: 0;
    color: white;
    font-size: 26px;
}

.status-card-sub-content{
    margin: 0;
    font-size: 13px;
    color: white;
}


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .admin-panel-status-card{
        width: 100%;
    }
}  
.admin-panel-table{
    background-color: white;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    font-size: 14px;
    width: 100%;
    border-collapse: collapse;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
    .responsive-table{
        overflow-x: auto;
        width: 100%!important;
    }
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .responsive-table{
        overflow-x: auto;
        width: 100%!important;
    }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
    .responsive-table{
        overflow-x: auto;
        width: 100%!important;
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .responsive-table{
      overflow-x: auto;
      width: 100%!important;
  }
}  
.admin-panel-table-head{
    padding: 5px;
    font-weight: bold;
    font-size: 17px;
    font-family: sans-serif;
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
}
.admin-panel-table-row{
    border-bottom: 2px solid rgb(238, 238, 238);
}
.admin-panel-table-cell{
    max-width: 130px;
    word-break: keep-all;
    padding: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    font-size: 15px;
    font-family: sans-serif;
}
.status-cards-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
    width: 100%;
}

.dual-chart-container{
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.chart-container{
  width: 50%;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.table-container{
  width: 50%;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.inner-table{
  box-shadow: none;
  width: 100%;
}

.chart-container h3{
  margin: 0;
  display: block;
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(238, 238, 238);
}

.full-width-chart-container h3{
  margin: 0;
  display: block;
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(238, 238, 238);
}

.table-container h3{
  margin: 0;
  display: block;
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(238, 238, 238); 
}

.dashboard-heading{
  margin-left: 20px;
  margin-top: 0;
}

.link-container{
  text-decoration: none;
}

.small-line-chart-container{
  width: 200px;
  height: 200px;
  background-color: white;
}

.store-status-section{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.store-status-dual-card{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.store-status-card{
  width: 50%;
  background-color: white;
  padding: 20px;
  text-align: left;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
  font-family: sans-serif;
}

.store-status-card-heading{
  font-family: 'nunito';
  margin:0;
  margin: 5px 0;
  font-size: 15px;
  font-weight: bold;
}

.store-status-percent{
  margin: 0;
  font-size: 35px;
  margin: 15px 0;
}

.store-status-big-text{
  font-size: 34px;
  font-weight: bold;
  margin: 0;
}

.store-status-mid-text{
  font-size: 17px;
  margin: 0;
  margin: 15px 0;
}

.store-status-small-text{
  font-size: 13px;
  margin: 0;
  color: rgb(143, 143, 143);
  margin: 15px 0;
}

.store-status-card-mid-div{
  position: relative;
}

.store-status-card-icon-container{
  position: absolute; 
  right: 0;
  top: 0;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-width-chart-container{
  width: 92%;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.small-dual-chat-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.small-chart{
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 40px;
}
/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
  .store-status-section{
    flex-direction: column;
  }

  .store-status-dual-card{
    flex-direction: row;
    width: 100%;
  }

  .store-status-card{
    width: 100%;
  }

  .dual-chart-container{
    flex-direction: column;
  }

  .chart-container{
    width: 80%;
  }

  .table-container{
    width: 80%;
  }

  .admin-panel-status-card{
    width: 50%!important;
  }
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    .status-cards-container{
        flex-direction: column;
    }

    .store-status-section{
      flex-direction: column;
    }

    .store-status-dual-card{
      flex-direction: row;
    }

    .store-status-card{
      width: 100%;
    }
    .dual-chart-container{
      flex-direction: column;
    }

    .chart-container{
      width: 80%;
    }

    .table-container{
      width: 80%;
    }

    .admin-panel-status-card{
      width: 50%!important;
    }
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .status-cards-container{
        flex-direction: column;
        width: 100%;
    }
    .dual-chart-container{
      flex-direction: column;
    }

    .chart-container{
      width: 80%;
    }

    .table-container{
      width: 80%;
    }
    .store-status-section{
      flex-direction: column;
    }

    .store-status-dual-card{
      flex-direction: column;
    }

    .store-status-card{
      width: 130%;
    }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
    .status-cards-container{
        flex-direction: column;
    }

    .dual-chart-container{
      flex-direction: column;
    }

    .chart-container{
      width: 80%;
    }

    .table-container{
      width: 80%;
    }

    .store-status-section{
      flex-direction: column;
    }

    .store-status-dual-card{
      flex-direction: column;
    }

    .store-status-card{
      width: 130%;
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .status-cards-container{
        flex-direction: column;
    }

    .dual-chart-container{
      flex-direction: column;
    }

    .chart-container{
      width: 80%;
    }

    .table-container{
      width: 80%;
    }

    .store-status-section{
      flex-direction: column;
    }

    .store-status-dual-card{
      flex-direction: column;
    }

    .store-status-card{
      width: 130%;
    }
}  
.admin-panel-button{
    color: white;
    background-color: #3368C6;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 20px;
    transition: 0.3s linear;
}

.admin-panel-button:hover{
    background-color: #0026ff;
}


.margin-null{
    margin: 0;
}
.admin-panel-main-content{
    width: 96%;
    padding: 40px;
    margin-top: 80px;
}

.admin-panel-main-content-maxify{
    width: 100%;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .admin-panel-main-content{
    padding: 10px;
}
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  .admin-panel-main-content{
    padding: 10px;
}
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
    .admin-panel-main-content{
        padding: 10px;
    }
    
}  






.admin-panel-form-input{
    width: 70%;
    padding: 10px;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 4px;
    height: 25px;
    transition: 0.4s ease;
    outline: none;
    font-size: 16px;
}

.admin-panel-form-input:focus{
    border-color: rgb(0, 217, 255);  
}

.form-input-container{
    display: flex;
    flex-direction: row;
    margin: 20px 0;
}

.form-input-label{
    margin-right: 20px;
    font-size: 16px;
    font-weight: bold;
    margin-top: 15px;
    width: 120px;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.admin-panel-form-textarea{
    width: 70%;
    padding: 10px;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 4px;
    transition: 0.4s ease;
    outline: none;
    font-size: 16px;
}

.admin-panel-form-textarea:focus{
    border-color: rgb(0, 217, 255);
}
.multi-page-form{
    box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
    border-radius: 10px;
    font-family: sans-serif;
    background-color: white;
    padding: 50px 40px;
}

.multipage-controller-list{
  list-style: none;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid rgb(211, 211, 211);
  padding: 0;
}

.multipage-controller-list li{
    padding: 10px 20px;
    border-bottom: 3px solid transparent;
    transition: 0.4s ease;
    transform: translateY(2px);
}

.multipage-controller-list li:hover{
    border-bottom: 3px solid #13CDD9!important;
    color: #13CDD9;
}

.multipage-controller-list-active{
    border-bottom: 3px solid #13CDD9!important;
    color: #13CDD9;
}

.multi-page-main-form-container{
    position: relative; 
}

.extra-label{
    margin-right: 75px;
    font-size: 16px;
    font-weight: bold;
    margin-top: 15px;
    width: 120px;
}
.admin-panel-form-select{
    width: 72%;
    padding: 10px;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 4px;
    height: 50px;
    transition: 0.4s ease;
    outline: none;
    font-size: 16px;
}

.admin-panel-form-select:focus{
    border-color: rgb(0, 217, 255);  
}


.radio-heading {
  margin-left: 10px;
  margin-right: 100px;
  width: 200px;
  display: inline-block;
}


.progress-outer-bar{
    height: 8px;
    background-color: #E9ECEF;
    border-radius: 20px;
    margin-bottom: 20px;
}

.progress-inner-bar{
   height: 100%;
   border-radius: 20px;
}
.profiles-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.basic-profile-container{
   width: 32%;
   background-color: white;
   margin: 15px;
   box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
   border-radius: 10px;
   font-family: sans-serif;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 20px;
   transition: 0.4s ease;
}
.basic-details{
    text-align: center;
}

.basic-details img{
    width:90px;
    height: 90px;
    border-radius: 50%;
}

.detailed-profile-container{
   width: 64%;
   background-color: white;
   margin: 15px;
   box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
   border-radius: 10px;
   font-family: sans-serif;
}

.employee-status-profile{
    width: 90%;
}

.horizontal-rule{
    border-color: rgb(255, 255, 255);
    background-color: rgb(255, 255, 255);
    width: 100%;
    border-bottom: none;
    margin-top: 60px;
}

.progress-bar-heading{
    color: gray;
    margin: 10px 0;
    font-size: 15px;
    letter-spacing: 1px;
    font-weight: normal;
}


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .profiles-container{
        flex-direction: column;
    }

    .basic-profile-container{
        width: 90%;
    }

    .detailed-profile-container{
        width: 100%;
    }
}   

.dropzone{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed #004594;
    border-radius: 15px;
    padding: 20px;
}

.choose-file-input{
    border: 1px solid rgb(219, 219, 219);
    padding: 10px;
}

.media-dropzone-container{
    background-color: white;
    padding: 50px;
    border-radius: 25px;
    box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
}
body {
  font-family: "Nunito", sans-serif !important;
}

.admin-panel-sidebar {
  background-color: #ffffff;
  width: 16%;
  height: auto;
  min-height: 100% !important;
  transition: 0.2s linear;
  display: flex;
  flex-direction: column;
  transition: 0.4s ease;
}

.admin-panel-sidebar-inner {
  position: fixed;
  width: 16%;
  transition: 0.4s ease;
  overflow-y: scroll;
  margin-top: 80px;
  height: 100%;
  padding-top: 30px;
}

/* width */
.admin-panel-sidebar-inner::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.admin-panel-sidebar-inner::-webkit-scrollbar-track {
  background: #f7f8fb;
}

/* Handle */
.admin-panel-sidebar-inner::-webkit-scrollbar-thumb {
  background: rgba(51, 104, 198,0.2);
}

/* Handle on hover */
.admin-panel-sidebar-inner::-webkit-scrollbar-thumb:hover {
  background: rgb(51, 104, 198);
}

.dropdown-header-link {
  position: relative;
}

.dropdown-header-link::after {
  content: "\276f";
  font-size: 13px;
  position: absolute;
  right: 0;
  margin-top: 0.5px;
}

.link-container .dropdown-header-link::after {
  content: "";
}

.hidden-class {
  display: none !important;
}

.hidden-sidebar {
  width: 0%;
  overflow: hidden;
}

.profile-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sidebar-top-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 16%;
  box-shadow: 0 0 10px 0 rgba(68, 102, 242, 0.05);
  position: fixed;
  top: 0;
  background-color: #ffffff;
  font-size: 25px;
  font-weight: bold;
  color: #3368C6;
}

.side-panel-profile-image {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(68, 102, 242, 0.3);
}

.profile-name {
  color: #3368C6;
  font-weight: bold;
  font-family: sans-serif;
  margin: 0;
  margin: 5px;
  margin-top: 20px;
  font-size: 15px;
}

.profile-designation {
  color: gray;
  margin: 0;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: bold;
}

.sidebar-icons {
  font-size: 18px !important;
}

.sidebar-link {
  padding: 12px 20px;
  transition: 0.5s ease;
  cursor: pointer;
  color: rgb(97, 97, 97) !important;
  position: relative;
}

.sidebar-link:hover {
  color: #3368C6 !important;
  padding-left: 25px;
}

.sidebar-link-header-div {
  display: flex;
  flex-direction: row;
}

.sidebar-link-header-div .sidebar-links-heading {
  margin: 0;
  margin-left: 10px;
  font-weight: 600;
  font-size: 14px;
  font-family: sans-serif;
  letter-spacing: 0.6px;
  margin-top: 2px;
}

.sidebar-links-first-sub-dropdown {
  display: none;
}

.visible-sidebar-link {
  display: block !important;
}

.sidebar-links-first-sub-dropdown li {
  padding: 5px;
  font-size: 14px;
  color: rgb(138, 138, 138);
}

.sidebar-links-first-sub-dropdown li:hover {
  color: #3368C6;
}

.sidebar-links-first-sub-dropdown li:after {
  right: 10px;
}

.hoverable-side-link {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.sub-dropdown-link {
  display: none;
}

.exit-icon{
  transform: rotate(180deg);
}


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  .admin-panel-sidebar-inner{
    width: 50%;
    background-color: white;
    z-index: 1000;
  }



  .sidebar-top-logo{
    width: 50%;
    z-index: 1000;
  }
}   

.admin-panel-profile-image{
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.nav-bar-admin{
    background-color: #FFFFFF;
    height: 80px;
    width: 84%;
    position: fixed;
    top: 0;
    box-shadow: 0 0 10px 0 rgba(68,102,242,.05);
    z-index: 1000;
}

.nav-bar-admin-maxify{
    width: 100%;
}
.nav-bar-admin-icon{
    font-size: 30px!important;
    color: #3368C6;
    margin-left: 10px;
    margin-right: 10px;
}

.nav-bar-admin-icon:hover{
    color: #2d9df8;
}

.nav-bar-admin-logo{
    font-size: 28px;
    font-weight: Bold;
    color: gray;
    margin-left: 30px;
}

.nav-bar-admin-left{
    float: left;
    height: 100%;
    display: flex;
    align-items: center;
}

.nav-bar-admin-right{
    float: right;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 30px;
}

.nav-bar-admin-search-bar-div{
    display: flex;
    flex-direction: row;
    overflow: hidden;
    border-radius: 30px;
    background-color: #F8F8F9;
    width: 300px;
    border: 1px solid rgb(238, 238, 238);
    height: 50%;
}

.search-icon-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    padding-left: 20px;
}

.search-icon{
    color: #8E8E8E;
}

.navbar-search-input{
    border: none;
    outline: none;
    background-color: #F8F8F9;
    padding: 12px;
    padding-left: 15px;
    font-size: 16px;
    font-family: sans-serif;
}



/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .nav-bar-admin-right{
        display: none;
    }

    .nav-bar-admin{
        width: 50%;
        right: 0;
    }

    .nav-bar-admin-maxify{
       width: 100%!important;   
    }

    
}   

.image-file-input{
    display: none;
}

.image-input{
    width:300px;
    height: 300px;
    position: relative;
    border: 1px solid rgb(209, 208, 208);
}

.image-input img{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
}

.image-input-overlay{
    background-color: rgba(0, 0, 0, 0.452);
    color: white;
    font-size: 14px;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: none;
    text-align: center;
    -webkit-animation: fadeIn ease 0.5s;
            animation: fadeIn ease 0.5s;
}

.image-input:hover .image-input-overlay{
    display: flex
}


@-webkit-keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }


@keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
.add-product-form{
    box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
    border-radius: 10px;
    font-family: sans-serif;  
    background-color: white;
    display: flex;
    justify-content:center;
    align-items: center;
    padding: 60px 10px;
}

.add-product-image-form{
   width: 40%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.add-product-details-form{
   width: 60%;
}

.add-product-image-input{
    height: 400px;
}

.add-product-btn{
    float: right;
}

.discard-product-btn{
    float: right;
    background-color: rgb(179, 179, 179);
}

.discard-product-btn:hover{
    background-color: gray;
}


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .add-product-form{
        flex-direction: column;
    }

    .add-product-details-form{
        width: 100%;
    }
}   

.product-card{
  width: 20%;
  background-color: white;
  padding: 30px 15px;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 10px;
  font-family: sans-serif;
  margin: 15px;
  text-align: left;
  box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
  min-height: 400px;
  max-height: 400px;
  position: relative;
}

.product-card-image{
  width: 80%;
  height: 65%;
}

.product-card-image-container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    max-height: 300px;
}

.product-card-details-container{
    width: 90%;
}

.star-icons-container{
  margin: 10px 0 4px 0;
}

.product-card-star-icon-golden{
  height: 18px!important;
  width: 18px!important;
  color:#FFA200;
}

.product-card-star-icon{
  height: 17px!important;
  width: 17px!important;
  color: #DDDDDD;
}

.product-card-description{
   margin: 0;
   font-size: 17px;
   color: #8b8b8b;
   margin-bottom: 3px;
}

.sale-price-product-card{
    font-weight: bold;
    font-size: 20px;
    margin-right: 6px;
}

.cost-price-product-card{
   color: rgb(179, 179, 179);
   text-decoration: line-through;
}

.delete-icon-product-card{
   position: absolute;
   right: 10px;
   top: 10px;
   color: #3368C6;
}

.delete-icon-product-card:hover{
  color: red;
}

.delete-prompt-container{
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-prompt{
  background-color: rgb(78, 78, 78);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  -webkit-animation: 0.8s popup ease;
          animation: 0.8s popup ease;
  overflow: hidden;
}

.delete-prompt-text{
  font-size: 20px;
  color: white;
}

.delete-prompt-delete-button{
    border: none;
    outline: none;
    padding: 10px;
    font-size: 17px;
    border-radius: 4px;
    color: white;
    background-color: rgb(221, 26, 0);
    margin: 10px;
    transition: 0.4s ease;
}

.delete-prompt-delete-button:hover{
  transform: scale(1.2);
}

.delete-prompt-cancel-button{
  border: none;
  outline: none;
  padding: 10px;
  font-size: 17px;
  border-radius: 4px;
  color: white;
  background-color: #8b8b8b;
  margin: 10px;
  transition: 0.4s ease;
}

.delete-prompt-cancel-button:hover{
  transform: scale(1.2);
}

@-webkit-keyframes popup {
  0%{
    transform: scale(0.1);
  }
  50%{
    transform: scale(1.2);
  }
  100%{
    transform: scale(1);
  }
}

@keyframes popup {
  0%{
    transform: scale(0.1);
  }
  50%{
    transform: scale(1.2);
  }
  100%{
    transform: scale(1);
  }
}


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .product-card{
    width: 30%;
  }

  .delete-prompt-container{
    width: 90%;
    height: 90%;
  }
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .product-card{
    width: 40%;
  }

  .delete-prompt-container{
    width: 90%;
    height: 90%;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  .product-card{
    width: 40%;
  }

  .delete-prompt-container{
    width: 90%;
    height: 90%;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .product-card{
      width: 80%;
    }

    .delete-prompt-container{
      width: 90%;
      height: 90%;
    }
}   

.centralizing-div{
    
}

.admin-panel-products-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.input-form-label{
    margin-right: 20px;
    font-size: 16px;
    font-weight: bold;
    margin-top: 15px;
    width: 120px;
}
.product-list-display{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 30px 0;
}

.add-btn{
    margin: 10px 30px;
}

.product-list-display span{
    background-color: lightgray;
    border-radius: 5px;
    margin: 2px 10px;
    padding: 10px;
}




.website-footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-image: url(/static/media/footerBackground.83f4c62e.jpg);
    position: relative;
    font-family: 'Poppins', sans-serif;
    position: relative;
}

.footer-about-content{
    color: rgb(168, 168, 168);
    font-size: 14px;
    text-align: justify;
}

.footer-content-section{
    width: 25%;
    position: relative;
    z-index: 2;
    margin: 20px;
    margin-bottom: 80px;
}

.footer-content-section h2{
  color: white;
  font-weight: 400;
  margin-bottom: 10px;
}

.footer-content-section h2::before{
    content: "";
    position: absolute;
    top: 60px;
    left: 0;
    width: 50px;
    height: 4px;
    background: #48c7ec;
}

.footer-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(2, 41, 131, 0.95)
}

.footer-contact-element{
    color: rgb(221, 221, 221);
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.footer-icon{
    font-size: 27px!important;
    margin-right: 10px;
    color: white;
}

.footer-links{
    text-decoration: none;
    display: block;
    color: rgb(170, 169, 169);  
    margin-bottom: 15px;  
}

.footer-links::before{
    content:"\276f";
    margin-right: 10px;
}

.footer-links:hover{
    color: #36C7EC;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
    
    .website-footer{
        flex-direction: column;
     }
 
     .footer-content-section{
        
         width: 90%;
         margin: 10px;
 
     }
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
    
}
/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
    .website-footer{
       flex-direction: column;
    }

    .footer-content-section{
       
        width: 90%;
        margin: 10px;

    }
    

}   
.main-navigation-bar{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0px 13px 25px -12px rgba(0,0,0,0.2);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
}

.navigation-links-container{
    display: flex;
    flex-direction: row;
    width: 60%;
    justify-content: flex-end;
}

.nav-logo-container{
    width: 20%;
    font-size: 24px;
    font-weight: bold;
    color: rgb(0, 110, 255);
    display: flex;
    flex-direction: row;
}

.logo-image{
    width: 54px;
    height: 40px;
}

.logo-content{
    width: 160px;
    height: 40px;
}

.mobile-menu-icon{
    display: none!important;
}

.mobile-menu{
    display: none;
}

.mobile-navigation-links-container{
    background-color: white!important;
    margin: 0;
    padding: 0;
}

.mobile-menu-link{
    display: block;
    text-decoration: none;
    color: #3368C6;
    padding: 20px;
    border-top: 1px solid #3368C6;
    font-weight: bold;
}

.mobile-menu-link:hover{
    color: blue;
}

@-webkit-keyframes fadeDown{
    0%{
      transform: translateY(-100px);
    }

    100%{
       transform: translateY(0);
    }
}

@keyframes fadeDown{
    0%{
      transform: translateY(-100px);
    }

    100%{
       transform: translateY(0);
    }
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
    .navigation-links-container{
        display: none;
    }
 
    .nav-logo-container{
       margin: 20px;
       margin-left: 5px;
       float: left;    
    }
 
    .main-navigation-bar{
        justify-content: flex-start;
        box-shadow: none;
    }

    .mobile-menu{
        display: block;
    }
 
    .mobile-menu-icon{
        display: block!important;
        position: absolute;
        right: 20px;
        font-size: 34px!important;
        color: black!important;
    }
 
    .mobile-menu-icon:hover{
        color: rgb(0, 132, 255)!important;
    }
 
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .navigation-links-container{
        display: none;
    }
 
    .nav-logo-container{
       margin: 20px;
       margin-left: 5px;
       float: left;    
    }
 
    .main-navigation-bar{
        justify-content: flex-start;
        box-shadow: none;
    }

    .mobile-menu{
        display: block;
    }
 
    .mobile-menu-icon{
        display: block!important;
        position: absolute;
        right: 20px;
        font-size: 34px!important;
        color: black!important;
    }
 
    .mobile-menu-icon:hover{
        color: rgb(0, 132, 255)!important;
    }
 
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
    .navigation-links-container{
        display: none;
    }
 
    .nav-logo-container{
       margin: 20px;
       margin-left: 5px;
       float: left;    
    }
 
    .main-navigation-bar{
        justify-content: flex-start;
        box-shadow: none;
    }

    .mobile-menu{
        display: block;
    }
 
    .mobile-menu-icon{
        display: block!important;
        position: absolute;
        right: 20px;
        font-size: 34px!important;
        color: black!important;
    }
 
    .mobile-menu-icon:hover{
        color: rgb(0, 132, 255)!important;
    }
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
   
   .navigation-links-container{
       display: none;
   }

   .nav-logo-container{
      margin: 20px;
      margin-left: 5px;
      float: left;    
   }

   .main-navigation-bar{
       justify-content: flex-start;
       box-shadow: none;
   }

   .mobile-menu{
       display: block;
   }

   .mobile-menu-icon{
       display: block!important;
       position: absolute;
       right: 20px;
       font-size: 34px!important;
       color: black!important;
   }

   .mobile-menu-icon:hover{
       color: rgb(0, 132, 255)!important;
   }

}   
.main-nav-link-container{
    text-decoration: none;
    transition: 0.4s ease;
    list-style-type: none;
    padding: 12px;
    color: #3A5181;
    font-weight: 600;
    border-bottom: 2px solid transparent;
}

.main-nav-link-container:hover{
    color: #396CC7;
    border-bottom: 2px solid #396CC7;
}

.main-nav-link{
    font-size: 17px;
}
.no-style-link{
 text-decoration: none;   
}

.top-nav-bar{
    background-color: #3368C6;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.top-nav-bar-left-side{
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center
}

.top-nav-bar-right-side{
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.top-nav-link{
    color: white;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    margin-right: 20px;
}

.top-nav-link-icon{
    font-size: 18px!important;
    margin-right: 5px;
    opacity: 0.7;
}

.top-nav-link:hover .top-nav-link-icon{
    opacity: 1;
}

.login-button-span{
  color: white;
  opacity: 0.7;
  margin-right: 10px;
}

.login-button-span:hover{
  opacity: 1;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .top-nav-bar-left-side{
    display: none;
  }

  .top-nav-bar-right-side{
    display: none;
  }

  
  .top-nav-bar{
    justify-content: flex-end;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  .top-nav-bar-left-side{
    display: none;
  }

  .top-nav-bar-right-side{
    display: none;
  }

  
  .top-nav-bar{
    justify-content: flex-end;
  }
  
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .top-nav-bar-left-side{
    display: none;
  }

  .top-nav-bar-right-side{
    display: none;
  }

  .top-nav-bar{
    justify-content: flex-end;
  }

  
}   


.back-drop{
    position: fixed;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
}
.auth-pop-up-form{
    position: fixed;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    width: 28%;
    top: 10%;
    left: 34%;
    -webkit-animation: fadeInDown 0.7s ease;
            animation: fadeInDown 0.7s ease;
}


.auth-pop-up-form-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.auth-pop-input{
    margin: 10px 0;
    font-size: 17px;
    padding: 10px;
    outline: none;
    border: none;
    border-bottom: 3px solid rgb(202, 202, 202);
    letter-spacing: 1px;
    width: 80%;
    transition: 0.4s ease;
}

.auth-pop-up-heading{
    font-size: 30px;
    font-weight: bold;
    margin: 10px 0 30px 0;
    color: #3368C6;
}

.auth-pop-input:focus{
    border-color: gray;
}

.auth-pop-button{
    width: 82%;
    padding: 10px;
    font-size: 20px;
    background-color: #3368C6;
    color: white;
    border: none;
    outline: none;
    border-radius: 3px;
    margin: 30px 10px;
    transition: 0.4s ease;
}

.auth-pop-button:hover{
    background-color: #0139b3;
}

.mode-change-link{
    margin: 0;
}

.mode-change-link:hover{
    color: blue;
} 

.invalid-cred-text{
    color: red;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.loading-spinner{
  padding: 10px;
  display: inline-block;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.822);
  border-bottom-color: transparent;
  border-left-color: transparent;
  -webkit-animation: loading 2s infinite ease-in-out;
          animation: loading 2s infinite ease-in-out;
}

@-webkit-keyframes fadeInDown {
    0% {
       opacity: 0;
       transform: translateY(-100px);
    }

    
    100% {
       opacity: 1;
       transform: translateY(0)
    }
 }

@keyframes fadeInDown {
    0% {
       opacity: 0;
       transform: translateY(-100px);
    }

    
    100% {
       opacity: 1;
       transform: translateY(0)
    }
 } 

@-webkit-keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(1440deg);
  }

} 

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(1440deg);
  }

} 

 /* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
   
    .auth-pop-up-form{
        width: 70%;
        left: 11%;   
    }
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
   
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
   
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .auth-pop-up-form{
        width: 70%;
        left: 5%;    
    }

}   
.info-with-image-section{
    background-color: white;
    display: flex;
    justify-content: center;
    padding: 70px 0;
}

.info-section-heading{
    font-family: "Poppins";
    margin: 0;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 40px;
    line-height: 48px;
    color: #18336c;
}

.info-with-image-content-section{
    width: 40%;
    padding: 50px 40px;
}

.info-image-container-left{
    width: 30%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.info-image-container-left::after{
    content: "\00A0";
    padding: 8px;
    height: 410px;
    background-color: #3368C6;
}

.info-image-container-right{
    width: 30%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}


.info-image-container-right::before{
    content: "\00A0";
    padding: 8px;
    height: 410px;
    background-color: #3368C6;
}


.info-section-content{
    text-align: justify;
    color: #8A9ABA;
}

.info-image{
    width: 100%;
    height: 100%;
}


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
    .info-with-image-section{
        flex-direction: column;
        align-items: center;
    }

    .info-image-container-right{
        width: 90%;
    }

    .info-image-container-left{
        width: 90%;
    }

    .info-with-image-content-section{
        width: 90%;
    }
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .info-with-image-section{
        flex-direction: column;
        align-items: center;
    }

    .info-image-container-right{
        width: 90%;
    }

    .info-image-container-left{
        width: 90%;
    }

    .info-with-image-content-section{
        width: 90%;
    }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
    .info-with-image-section{
        flex-direction: column;
        align-items: center;
    }

    .info-image-container-right{
        width: 90%;
    }

    .info-image-container-left{
        width: 90%;
    }

    .info-with-image-content-section{
        width: 90%;
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .info-with-image-section{
        flex-direction: column;
        align-items: center;
    }

    .info-image-container-right{
        width: 90%;
    }

    .info-image-container-left{
        width: 90%;
    }

    .info-with-image-content-section{
        width: 90%;
    }
}   
.about-big-image{
    height: 600px!important;
}
.sub-heading{
    font-family: "Poppins";
    margin: 0;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 26px;
    line-height: 35px;
    color: #18336c;
}

.sub-points{
    text-align: justify;
    color: #8A9ABA;
}

.sub-small-heading{
    font-family: "Poppins";
    margin: 0;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 18px;
    line-height: 28px;
    color: #18336c;
}
.contact-us-cards-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
}

.contact-us-card{
    background-color: white;
    border-radius: 10px;
    margin: 20px;
    padding: 15px 40px;
    box-shadow: 0 0 10px 0 rgba(43,52,59,.1);
    width: 25%;
}

.contact-us-card-heading{
    font-family: "Poppins";
    font-weight: 700;
    text-transform: capitalize;
    font-size: 20px;
    margin: 10px 0;
    line-height: 48px;
    color: #18336c;
}

.contact-us-label{
    color: rgb(0, 70, 161);
}

.contact-us-value{
    color: #8E9CBA;
}

.contact-us-form-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 150px;
    height: 500px;
}

.contact-us-image-container{
    width: 25%;
    height: 100%;
}

.contact-us-image-container img{
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 100px;
}

.dual-input-container-contact-form{
    display: flex;
    flex-direction: row;
}

.contact-us-form-inner-container{
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(43,52,59,.1);
    padding: 30px;
    transform: translateX(-30px);
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 100px;
}

.contact-form-input{
    background-color: #F5F9FD;
    border: none;
    margin: 20px;
    color: #8093a8;
    padding: 13px 15px;
    font-weight: 400;
    text-transform: inherit;
    border: 1px solid #F5F9FD;
    font-size: 15px;
    outline: none;
    line-height: inherit;
    width: 50%;
    transition: 0.4s ease;
    border-radius: 2px;
    font-size: 15px;
}

.contact-form-input:focus{
    border-color: #3368C6;
}

.contact-form-input::-webkit-input-placeholder{
    color: #8E9CBA;
}

.contact-form-input:-ms-input-placeholder{
    color: #8E9CBA;
}

.contact-form-input::-ms-input-placeholder{
    color: #8E9CBA;
}

.contact-form-input::placeholder{
    color: #8E9CBA;
}

.contact-us-form{
    width: 100%;
    text-align: center;
}

.contact-form-heading{
    font-family: "Poppins";
    margin: 25px;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 34px;
    line-height: 48px;
    color: #18336c;
    text-align: left;
}

.contact-form-textarea{
    background-color: #F5F9FD;
    border: none;
    margin: 20px;
    color: #8093a8;
    padding: 13px 15px;
    font-weight: 400;
    text-transform: inherit;
    border: 1px solid #F5F9FD;
    font-size: 15px;
    outline: none;
    line-height: inherit;
    width: 100%;
    transition: 0.4s ease;
    border-radius: 2px;
    font-size: 15px;
}

.contact-form-textarea:focus{
    border-color: #3368C6;
}

.contact-form-textarea::-webkit-input-placeholder{
    color: #8E9CBA;
}

.contact-form-textarea:-ms-input-placeholder{
    color: #8E9CBA;
}

.contact-form-textarea::-ms-input-placeholder{
    color: #8E9CBA;
}

.contact-form-textarea::placeholder{
    color: #8E9CBA;
}

.contact-form-button{
  background-color: #3368c6;
  color: #fff;
  padding: 11px 28px;
  border-radius: 3px;
  transition: all .2s ease-in-out;
  line-height: normal;
  font-size: 14px;
  border: none;
  outline: none;
  margin-top: 10px;
}

.contact-form-button:hover{
    background-color: darkblue;
}

.map-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
}

.map{
    width: 100%;
    height: 100%;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    .contact-us-cards-container{
        flex-direction: column;
    }

    .contact-us-card{
        width: 50%;
    }
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .contact-us-cards-container{
        flex-direction: column;
    }

    .contact-us-card{
        width: 50%;
    }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
    .contact-us-cards-container{
        flex-direction: column;
    }

    .contact-us-card{
        width: 50%;
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .contact-us-cards-container{
        flex-direction: column;
    }

    .contact-us-card{
        width: 50%;
    }

    .contact-us-image-container{
        display: none;
    }

    .contact-us-form-container{
        width: 100%;
        display: block;

    }

    .contact-us-form-inner-container{
        width: 100%;
        border-radius: 0;
    }

    .contact-us-form{
        width: 100%;
    }

}   
.arrow-container-left{
  position: absolute;
  z-index: 1;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  left: 10px;
}

.arrow-container-right{
  position: absolute;
  z-index: 1;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  right: 10px;
}

.black-bg{
  background-color: rgb(36, 36, 36);
}

.carousel{
  height: 550px;
  padding: 0;
  margin: 0;
  position: relative;
}
.carousel-item-1{
  animation: fadeIn linear 2s;
  -webkit-animation: fadeIn linear 2s;
  -moz-animation: fadeIn linear 2s;
  -o-animation: fadeIn linear 2s;
  -ms-animation: fadeIn linear 2s;
  position: relative;
  overflow: hidden;
  height: 100%;
  background-image: url(/static/media/slide1.9b44b724.jpg);
  background-repeat: no-repeat;
  background-size: cover;

}
.carousel-item-2{
  animation: fadeIn linear 2s;
  -webkit-animation: fadeIn linear 2s;
  -moz-animation: fadeIn linear 2s;
  -o-animation: fadeIn linear 2s;
  -ms-animation: fadeIn linear 2s;
  position: relative;
  overflow: hidden;
  height: 100%;
  background-image: url(/static/media/slide2.da999fa2.jpg);
  background-repeat: no-repeat;
  background-size: cover;


}
.carousel-item-3{
  animation: fadeIn linear 2s;
  -webkit-animation: fadeIn linear 2s;
  -moz-animation: fadeIn linear 2s;
  -o-animation: fadeIn linear 2s;
  -ms-animation: fadeIn linear 2s;
  position: relative;
  overflow: hidden;
  height: 100%;
  background-image: url(/static/media/slide3.1cc7221c.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel-button-container{
  -webkit-animation: fadeInBottom ease 1s;
          animation: fadeInBottom ease 1s;
}
.headings-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.heading1{
    position: relative;
    background-color: rgba(51, 111, 240, 0.8);
    color: white;
    width:60%;  
    padding: 20px;
    text-align: center;
    font-size: 50px;
    margin: 0;
    font-weight: bold;
    border-radius: 30px;
    -webkit-animation: fadeInBottom ease 1s;
            animation: fadeInBottom ease 1s;
}
.heading2{
  background-color: rgba(104, 104, 104, 0.8);
  color: white;
  width: 50%;
  padding: 20px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin: 1%;
  border-radius: 30px;
  -webkit-animation: fadeInBottom ease 0.2s;
          animation: fadeInBottom ease 0.2s;
}


.carousel-button-container{
  display: flex;
  justify-content: center;
  align-items: center;
}


.forward-slide{
  color: rgba(219, 208, 208, 0.411);
}

.forward-slide:hover{
  color: rgba(224, 221, 221, 0.9);
}


.previous-slide{
  color: rgba(219, 208, 208, 0.411);
}

.previous-slide:hover{
  color: rgba(224, 221, 221, 0.9);
}

.no-style-link{
  text-decoration: none;
}

.learn-more-button{
  color: white;
  background-color: rgba(0, 119, 255, 0.8);
  outline: none!important;
  border:none;
  font-size: 16px;
  padding: 15px;
  border-radius: 5px;
  margin: 10px;
}

.learn-more-button:hover{
  background-color: rgb(0, 119, 255);
}

.contact-us-button{
  color: white;
  background-color: rgba(150, 150, 150, 0.8);
  outline: none!important;
  border:none;
  font-size: 16px;
  padding: 15px;
  border-radius: 5px;
  margin: 10px;
}

.contact-us-button:hover{
  background-color: rgb(150, 150, 150);
}
@keyframes fadeIn{
  0% {
    opacity:0.8;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0.8;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeInBottom {
  from {
      opacity: 0;
      transform: translateY(100%);
  }
  to { opacity: 1 }
}

@keyframes fadeInBottom {
  from {
      opacity: 0;
      transform: translateY(100%);
  }
  to { opacity: 1 }
}


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
   .heading1{
      font-size: 25px;
      padding: 50px;
      padding-top: 20px;
      padding-bottom: 20px;
   }

   .heading2{
     font-size: 25px;
     padding: 50px;
     padding-top: 20px;
     padding-bottom: 20px;
   }

   .forward-slide{
     font-size: 30px!important;
   }
   
   .previous-slide{
     font-size: 30px!important;
   }
} 
.testimonial{
    background-color: white;
    text-align: center;
    position: relative;
    z-index: 0;
    height: 100%;
    background-image: url(/static/media/testimonialbackground.159680ef.png);
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.testimonial-top-heading{
  color: #3368c6;
  font-weight: bold;
  letter-spacing: 1.3px;
  font-size: 14px;
  margin: 0;
  font-family: "Roboto",Arial,Helvetica,sans-serif;
  padding: 10px;
}

.testimonial-main-heading{
  font-family: "Poppins",Arial,Helvetica,sans-serif;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 36px;
  line-height: 48px;
  color: #18336c;
  margin: 0;
  padding: 10px;
}

.testimonial-heading-text{
  font-family: "Roboto",Arial,Helvetica,sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #788aaf;
  width: 40%;
}

.testimonial-cards-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .testimonial-cards-container{
    flex-direction: column;
  }

  .testimonial-heading-text{
    width: 90%;
  }
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .testimonial-cards-container{
    flex-direction: column;
  }

  .testimonial-heading-text{
    width: 90%;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  .testimonial-cards-container{
    flex-direction: column;
  }

  .testimonial-heading-text{
    width: 90%;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .testimonial-cards-container{
    flex-direction: column;
  }

  .testimonial-heading-text{
    width: 90%;
  }
}   
.testimonial-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    margin: 30px;
}

.testimonial-card{ 
   background-color: white;
   box-shadow: 0 0 10px 0 rgba(43,52,59,.1);
   color: #788aaf;
   text-align: justify;
   font-style: normal;
   font-weight: 400;
   padding: 20px;
   padding-top: 40px;
   margin: 0;
   font-size: 16px;
   line-height: 26px;
   font-family: "Roboto";
   min-height: 160px;
   max-height: 160px;
   position: relative;
}

.inverted-commas-image{
    position: absolute;
    width: 120px;
    height: 100px;
    top: -50px;
    right: -20px;
}

.testimonial-person{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.person-image{
    width: 82px;
    height: 82px;
    border-radius: 50%;
}

.person-name{
    font-size: 17px;
    font-family: "Poppins",Arial,Helvetica,sans-serif;
    font-weight: 600;
    color: #18336c;
    margin-left: 10px;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
    .testimonial-container{
        width: 100%;
     }
 
     .testimonial-card{
         min-height: 200px;
         max-height: 200px;
     }  
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .testimonial-container{
        width: 100%;
     }
 
     .testimonial-card{
         min-height: 200px;
         max-height: 200px;
     }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
    .testimonial-container{
        width: 100%;
     }
 
     .testimonial-card{
         min-height: 200px;
         max-height: 200px;
     }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .testimonial-container{
       width: 100%;
    }

    .testimonial-card{
        min-height: 200px;
        max-height: 200px;
    }
}   
.website-stats-section{
    background-color: #3368C6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
}

.stats-main-heading{
    color: white;
    margin: 0;
    margin: 10px 0;
    font-family: "Poppins",Arial,Helvetica,sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 40px;
    line-height: 48px;
}

.stats-sub-heading{
    color: rgb(240, 238, 238);
    margin: 0;
    margin: 10px 0;
    font-size: 18px;
}

.website-stats-section-cards-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.website-stats-section-cards{
    border: 2px double white;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 15%;
    margin: 30px;
    border-radius: 5px;
    display: inline-flex;
    padding: 20px;
}

.website-stats-content-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
}

.website-stats-number{
   font-size: 45px;
   margin: 10px 0;
   transform: translateX(7px);
} 

.website-stats-title{
   margin: 10px 0;
}

.website-stats-icon-container{
    display: flex;
    width: 30%;
}

.website-stats-icons{
    font-size: 100px!important;
}


.products-slider-list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}

.home-page-products-images-container{
    background-color: #ffffffea;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.home-page-products-images-container h1{
    font-family: "Poppins";
    margin: 30px;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 40px;
    line-height: 48px;
    color: #18336c;
}

.home-page-products-images-container-inner::-webkit-scrollbar{
    display: none;
}

.home-page-products-images-container-inner{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
}


.home-page-product-image-container{
    min-height: 400px;
    max-height: 400px;
    max-width: 250px;
    min-width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    -webkit-animation: scrollanimation 40s linear infinite;
            animation: scrollanimation 40s linear infinite;
}

.home-page-product-image{
   width: 100%;
}

.product-list-background-image{
    position: absolute;
    background-image: url(/static/media/product-list-home.87b0193b.jpg);
    width: 100%;
    height: 100%;
    z-index: -1;
}

@-webkit-keyframes scrollanimation {

    100%{
        transform: translateX(-600%);
    }
}

@keyframes scrollanimation {

    100%{
        transform: translateX(-600%);
    }
}
/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    .stats-main-heading{
        text-align: center;
        padding: 5px;
    }
 
    .stats-sub-heading{
        text-align: center;
        padding: 2px;
    }
 
    .website-stats-section-cards-container{
        flex-direction: column;
    }
 
    .website-stats-section-cards{
        width: 60%;
    }
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .stats-main-heading{
        text-align: center;
        padding: 5px;
    }
 
    .stats-sub-heading{
        text-align: center;
        padding: 2px;
    }
 
    .website-stats-section-cards-container{
        flex-direction: column;
    }
 
    .website-stats-section-cards{
        width: 60%;
    }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
    .stats-main-heading{
        text-align: center;
        padding: 5px;
    }
 
    .stats-sub-heading{
        text-align: center;
        padding: 2px;
    }
 
    .website-stats-section-cards-container{
        flex-direction: column;
    }
 
    .website-stats-section-cards{
        width: 60%;
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
   .stats-main-heading{
       text-align: center;
       padding: 5px;
   }

   .stats-sub-heading{
       text-align: center;
       padding: 2px;
   }

   .website-stats-section-cards-container{
       flex-direction: column;
   }

   .website-stats-section-cards{
       width: 60%;
   }
}   
.website-product-card{
    box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
    background-color: #031B31;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 400px;
    padding: 20px;
    border-radius: 10px;
    margin: 0 30px 30px 30px;
}

.website-product-image-container{
   display: flex;
   justify-content: center;
   align-items:center;
   height: 70%;
   width: 100%;
}

.website-product-image-container img{
    width: 90%;
    height: 90%;
}

.website-product-details-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
}

.website-product-details-container p{
    margin: 0;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    font-size: 25px;
    letter-spacing: 1px;
}

.website-product-details-container p:nth-child(2){
    color:#FFA500;
    font-size: 14px;
}


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    .website-product-card{
        width: 60%;
    }
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .website-product-card{
        width: 40%;
    }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
    .website-product-card{
        width: 40%;
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .website-product-card{
        width: 80%;
    }
}  
.website-product-card-container{
    padding: 50px 0;
}

.main-website-products-card-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap:wrap;
    padding: 30px;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
}  

