.dropzone{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed #004594;
    border-radius: 15px;
    padding: 20px;
}

.choose-file-input{
    border: 1px solid rgb(219, 219, 219);
    padding: 10px;
}
