.admin-panel-status-card{
    display: flex;
    flex-direction: row;
    box-shadow: 0 0 5px 2px lightgray;
    background-color: white;
    border-radius: 25px;
    margin: 15px;
    width: 25%;
    height: 130px;
}

.status-card-icon-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.status-card-icon{
    color: white;
    font-size: 34px!important;
}

.status-card-text-container{
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 0;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.status-card-main-content{
    margin: 0;
    color: white;
    font-size: 26px;
}

.status-card-sub-content{
    margin: 0;
    font-size: 13px;
    color: white;
}


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .admin-panel-status-card{
        width: 100%;
    }
}  