@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

.info-with-image-section{
    background-color: white;
    display: flex;
    justify-content: center;
    padding: 70px 0;
}

.info-section-heading{
    font-family: "Poppins";
    margin: 0;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 40px;
    line-height: 48px;
    color: #18336c;
}

.info-with-image-content-section{
    width: 40%;
    padding: 50px 40px;
}

.info-image-container-left{
    width: 30%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.info-image-container-left::after{
    content: "\00A0";
    padding: 8px;
    height: 410px;
    background-color: #3368C6;
}

.info-image-container-right{
    width: 30%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}


.info-image-container-right::before{
    content: "\00A0";
    padding: 8px;
    height: 410px;
    background-color: #3368C6;
}


.info-section-content{
    text-align: justify;
    color: #8A9ABA;
}

.info-image{
    width: 100%;
    height: 100%;
}


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
      
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
    .info-with-image-section{
        flex-direction: column;
        align-items: center;
    }

    .info-image-container-right{
        width: 90%;
    }

    .info-image-container-left{
        width: 90%;
    }

    .info-with-image-content-section{
        width: 90%;
    }
    
}   

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .info-with-image-section{
        flex-direction: column;
        align-items: center;
    }

    .info-image-container-right{
        width: 90%;
    }

    .info-image-container-left{
        width: 90%;
    }

    .info-with-image-content-section{
        width: 90%;
    }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
    .info-with-image-section{
        flex-direction: column;
        align-items: center;
    }

    .info-image-container-right{
        width: 90%;
    }

    .info-image-container-left{
        width: 90%;
    }

    .info-with-image-content-section{
        width: 90%;
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .info-with-image-section{
        flex-direction: column;
        align-items: center;
    }

    .info-image-container-right{
        width: 90%;
    }

    .info-image-container-left{
        width: 90%;
    }

    .info-with-image-content-section{
        width: 90%;
    }
}   